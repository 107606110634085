* {
  user-select: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #fff;
    box-shadow: inset 0 0 20px 20px #27282d;
    transition: background-color 0s 5000s;
}

.auth-bg {
  background-image: url('/public/media/cover.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  min-width: 100vw;
}

.login-container {
  max-width: 400px;
  border-radius: 5px;
  border: solid 1px #4b4b4b;
  background-color: #27282d;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login-container .form-control {
  height: 45px;
}

.login-col {
  padding: 30px 30px 15px 30px;
}

.show-btn {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 0;
}

.btn-outline-success {
  border-radius: 20px;
  border: solid 1px #7ed321;
  color: #7ed321;
  width: 100%;
  max-width: 340px;
}

.btn-outline-success:hover {
  background-color: #7ed321;
  color: #000;
  border-color: #7ed321;
}

.btn-outline-success:active {
  background-color: transparent !important;
  color: #7ed321 !important;
  border-color: #7ed321 !important;
}

.btn-outline-success.disabled, .btn-outline-success:disabled,
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #585858;
  border: solid 1px #585858;
  background-color: transparent;
}

.form-control {
  border-top: none;
  border-right: none;
  border-left: none;
  border-radius: 0;
  border-bottom: 1px solid #4b4b4b;
  color: #fff;
  background-color: #27282d;
}

.form-control:focus{
  color: #fff;
  background-color: #27282d;
  box-shadow: none;
  border-bottom: 1px solid #4b4b4b;
}

.form-control:disabled {
  background-color: #27282d;
  color: #585858;
}

.form-control::placeholder {
  color: #585858;
}

.nav-panel-pos {
  position: absolute;
  left: 0;
  bottom: 0
}

.place-button {
background-color: white;
}

.place-button:hover {
background-color: rgb(212, 210, 210);
}

.place-button:active {
background-color: rgb(153, 152, 152);
}

.place-button-img {
width: 25px;
height: 25px;
border-radius: 15px;
background-color: transparent;
}

.place-button-img:hover {
background-color: rgb(212, 210, 210);
}

.place-button-img:active {
background-color: rgb(153, 152, 152);
}

.place-button-img img {
width: 15px;
height: 15px;
}

.img-button {
background-color: white;
color: black;
width: 30px;
height: 30px;
border-radius: 15px;
}

.img-button:hover {
background-color: rgb(212, 210, 210);
color: black;
}

.img-button:active {
background-color: rgb(153, 152, 152);
color: black;
}

.img-button img {
width: 20px;
height: 20px;
}

.card-content {
flex-grow: 1;
height: 450px;
overflow-y: auto;
}

.add-places-button {
background-color: transparent;
width: 200px;
height: 200px;
}

.add-places-button:hover {
background-color: rgb(212, 210, 210);
}

.file-edit-component {
background-color: transparent;
color: black;
width: 100%;
height: 60px;
}

.hide-navigation-button {
position: absolute;
left: 10px;
bottom: 10px;
width: 50px;
height: 50px;
border-radius: 25px;
background-color: white;
border: 2px solid black;
}

.hide-navigation-button:hover {
width: 52px;
height: 52px;
background-color: rgb(212, 210, 210);
}

.hide-navigation-button:active {
width: 52px;
height: 52px;
background-color: rgb(153, 152, 152);
}